import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser } from 'state/ducks/user/actions';
import ProfileDescription from './components/ProfileDescription';

const Profile = ({ history, match }) => {
  const username = match.params.username;
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const { data: user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUser(username));
  }, [history]);

  return (
    <Fragment>
      <div className="bg-black text-center">
        <a href="tappcard.io">
          <p
            className="text-white"
            style={{ fontSize: '1.4rem', padding: '0.5rem 0' }}
          >
            tappcard.io
          </p>
        </a>
      </div>
      {user && user.username === username ? (
        <ProfileDescription history={history} />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default Profile;
